<template>
  <div v-if="model">
    <section class="fact" :style="{'background-image': 'url('+model.fileName +')'}">
      <img src="/images/bg/factheader.png" class=" wow fadeInDown" alt />
      <div v-html="model.description" class="description wow fadeInUp"></div>
    </section>
  </div>
</template>

<script>
import WOW from "wow.js";
import store from "../store/index";
new WOW().init();
export default {
  name: "fact",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch("setNextPlaylist");
      }, (this.model.displaySecond || 15) * 1000);
    }
  },
  computed: {
    model() {
      return this.$store.state.backgroundImage;
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/animate/animate.css";
@import "../assets/scss/news.scss";

.fact {
  position: fixed;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fact > img {
  position: fixed;
  width: 100%;
  height: 100px;
  top: 120px;
  left: 0;
  right: 0;
  margin: auto;
  margin-right: -10px;
}

.fact .description {
  position: fixed;
  width: 825px;
  min-height: 100px;
  max-height: 600px;
  top: 230px;
  left: 100px;
  right: 0;
  margin: auto;
  color: white;
  background: #8a8a8adb;
  font-size: 40px;
  padding: 25px;
  line-height: 140%;
  border: 6px solid white;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 11;
  -webkit-box-orient: vertical;
}
</style>
